import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CustomerSearchComponent } from "../shared/customer-search/customer-search.component";
import { FormsModule } from "@angular/forms";
import { DropDownsModule } from "@progress/kendo-angular-dropdowns";
import { ToastrModule } from "ngx-toastr";
import { LoaderComponent } from './loader/loader.component';
import { AuditHistoryComponent } from './audit-history/audit-history.component';
import { DialogModule } from "@progress/kendo-angular-dialog";
import { GridModule } from "@progress/kendo-angular-grid";
import { BooleanIndicatorComponent } from "./boolean-indicator/boolean-indicator.component";

@NgModule({
  declarations: [CustomerSearchComponent, LoaderComponent, AuditHistoryComponent, BooleanIndicatorComponent],
  imports: [CommonModule, FormsModule, DropDownsModule, ToastrModule.forRoot(), DialogModule, GridModule],
  exports: [CustomerSearchComponent, LoaderComponent, AuditHistoryComponent, BooleanIndicatorComponent],
})
export class SharedModule {}
